define("discourse/plugins/discourse-docs/discourse/templates/components/docs-search", ["exports", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <span class="docs-search-wrapper">
    <Input
      @type="text"
      @value={{readonly searchTerm}}
      class="no-blur docs-search-bar"
      autocorrect="off"
      placeholder={{i18n "docs.search.placeholder"}}
      autocapitalize="off"
      {{on "keydown" this.onKeyDown}}
    />
  
    {{#if searchTerm}}
      <DButton
        @action={{action "clearSearch"}}
        class="clear-search"
        @label="docs.search.clear"
      />
    {{else}}
      {{d-icon "search"}}
    {{/if}}
  </span>
  */
  {
    "id": "0So+Xwu7",
    "block": "[[[10,1],[14,0,\"docs-search-wrapper\"],[12],[1,\"\\n  \"],[8,[39,1],[[24,0,\"no-blur docs-search-bar\"],[24,\"autocorrect\",\"off\"],[16,\"placeholder\",[28,[37,2],[\"docs.search.placeholder\"],null]],[24,\"autocapitalize\",\"off\"],[4,[38,4],[\"keydown\",[30,0,[\"onKeyDown\"]]],null]],[[\"@type\",\"@value\"],[\"text\",[28,[37,3],[[30,0,[\"searchTerm\"]]],null]]],null],[1,\"\\n\\n\"],[41,[30,0,[\"searchTerm\"]],[[[1,\"    \"],[8,[39,6],[[24,0,\"clear-search\"]],[[\"@action\",\"@label\"],[[28,[37,7],[[30,0],\"clearSearch\"],null],\"docs.search.clear\"]],null],[1,\"\\n\"]],[]],[[[1,\"    \"],[1,[28,[35,8],[\"search\"],null]],[1,\"\\n\"]],[]]],[13],[1,[28,[32,0],[\"[[\\\"The `searchTerm` property path was used in the `discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.searchTerm}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}],[\\\"The `searchTerm` property path was used in the `discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.searchTerm}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"span\",\"input\",\"i18n\",\"readonly\",\"on\",\"if\",\"d-button\",\"action\",\"d-icon\"]]",
    "moduleName": "discourse/plugins/discourse-docs/discourse/templates/components/docs-search.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
});